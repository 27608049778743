header {

  &.header-obrigado {
    border-bottom: 15px solid #dff0f0; }

  .header {
    padding: 40px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include below(desktop) {
      padding: 15px 0; } }

  .logo {
    width: 471px;
    @include below(large-desktop) {
      width: 340px; }

    @include below(desktop) {
      margin: 0 auto;
      display: table;
      width: 310px; } }

  .phone-header {
    font-size: 24px;
    font-weight: 700;
    display: flex;
    align-items: flex-end;

    @include below(desktop) {
      display: none; }

    i {
      margin: 0 7px; }

    p {
      display: flex;
      align-items: flex-end; }

    img {
      margin-right: 10px;
      margin-bottom: 5px;
      width: 32px;
      height: 32px; }

    span {
      font-size: 14px;
      margin-bottom: 6px;
      margin-right: 5px; } }

  .social {
    display: flex;

    @include below(desktop) {
      display: none; }
    a {
      width: 32px;
      height: 32px;
      background-color: #00918c;
      @include flexCenter;
      color: $white;
      font-size: 18px;
      border-radius: 50%;
      margin-left: 10px;

      transition: all 0.3s ease;
      &:hover {
        background: #237874; } } } }


#section-1 {
  position: relative;

  .inner-section-1 {
    background: rgb(63,145,140);
    background: linear-gradient(180deg, rgba(63,145,140,1) 0%, rgba(75,171,166,1) 50%, rgba(86,196,190,1) 100%);
    border-top: 15px solid #dff0f0;
    border-bottom: 15px solid #dff0f0;
    color: $white;
    padding: 30px 0 40px; }

  .container {

    position: relative;

    @include above(tablet) {
      display: flex;
      justify-content: space-between; } }

  .column-left {


    @include above(tablet) {
      width: 420px; }


    .text1 {
      font-size: 38px;
      font-weight: 700;
      letter-spacing: -1.09px;
      line-height: 45.81px;

      @include below(desktop) {
        text-align: center;
        font-size: 32px; } }

    .text2 {
      font-size: 76px;
      font-weight: 800;
      letter-spacing: -0.96px;
      margin-top: -10px;
      margin-left: 90px;
      line-height: 90px;

      @include below(desktop) {
        text-align: center;
        margin-left: 0;
        font-size: 64px; } }

    .text3 {
      text-align: center;
      font-size: 20px;
      font-weight: 600;
      line-height: 24px;

      @include below(desktop) {
        font-size: 16px;

        br {
          display: none; } } } }


  .column-right {
    width: 621px;
    height: 396px;
    border: 1px solid rgba(2, 115, 109, 0.34);
    padding: 15px;


    @include above(tablet) {
      position: absolute;
      right: 0;
      top: -370px; }

    @include below(large-desktop) {
      width: 480px; }


    @include below(desktop) {
      width: 100%;
      height: 100%;
      margin-top: 30px; }

    .video {
      position: relative;
      padding-bottom: 62.5%;
      overflow: hidden;
      height: 100%;
      iframe {
        position: absolute;
        height: 100%;
        width: 100%;
        left: 0;
        top: 0; } } } }


#section-2 {
  padding-top: 80px;

  @include below(desktop) {
    padding: 30px 0 0; }

  .text1 {
    p {
      text-align: center;
      color: #000000;
      font-size: 30px;
      font-weight: 700;
      letter-spacing: -0.75px;

      @include below(desktop) {
        font-size: 22px; } } }

  img {
    @include below(desktop) {
      margin: 0 auto;
      display: table; } }

  .row-1 {
    margin-top: 20px;


    .list-check {
      margin-bottom: 30px; } } }

.list-check {

  @include below(desktop) {
    display: table;
    margin-left: auto;
    margin-right: auto; }

  ul {
    @include below(desktop) {

      display: flex;
      flex-direction: column;
      margin-top: 10px; }
    li {
      font-size: 21px;
      font-weight: 700;
      color: #4c4c4c;
      position: relative;
      padding-left: 50px;

      @include below(desktop) {
        font-size: 16px;
        padding-left: 40px; }

      &:before {
        content: '';
        background-image: url('../images/icon-check.svg');
        width: 34px;
        height: 34px;
        position: absolute;
        left: 0;
        background-size: contain;

        @include below(desktop) {
          width: 24px;
          height: 24px; } }

      + li {
        margin-top: 15px; } } } }


#section-3 {
  background: #edf8f8;
  border-top: 15px solid #bfe8e6;
  border-bottom: 15px solid #bfe8e6;

  .grid-images {

    margin-top: 30px;

    @include above(tablet) {
      display: grid;
      grid-template-columns: 33.33% 33.33% 33.33%;
      grid-gap: 0 25px; }

    .item {
      padding: 10px;
      border: 1px solid rgba(2, 115, 109, 0.34);

      @include below(desktop) {

        + .item {
          margin-top: 15px; } }

      img {
        width: 100%; } } }

  .text2 {
    font-size: 20px;
    text-align: center;
    margin-top: 20px;
    font-weight: 700;

    @include below(desktop) {
      font-size: 18px; } }

  .topics {
    margin-top: 35px;

    @include above(tablet) {
      display: grid;
      justify-content: center;
      grid-template-columns:  283px 283px 283px;
      grid-gap: 20px; }

    .item {
      height: 116px;
      border-radius: 5px;
      border: 1px solid #30827e;
      background-color: #ffffff;
      color: #30827e;
      font-size: 22px;
      font-weight: 700;
      display: flex;
      align-items: center;
      justify-content: center;

      @include below(desktop) {
        height: 85px;
        + .item {
          margin-top: 15px; } } } } }


#section-4 {

  img {
    width: 100%;
    object-fit: cover;
    object-position: center center;
    height: 300px;
    border-bottom: 15px solid #bfe8e6;

    @include below(desktop) {
      height: 140px; } } }


#section-5 {
  border-bottom: 15px solid #bfe8e6;

  .perguntasRespostas {
    .item {
      padding: 30px 0;
      border-bottom: 1px solid #9fc7c5;

      @include below(desktop) {
        text-align: center; }

      .primary-text {
        margin-top: 10px;

        @include below(desktop) {
          text-align: center; } } } }


  .inner-section-1 {
    margin-top: 80px;

    @include below(desktop) {
      margin-top: 30px; }

    .third-text {
      margin-top: 5px; } }

  .row-1 {
    margin-top: 45px;

    @include below(desktop) {
      margin-top: 30px;

      img {
        width: 270px;
        margin: 0 auto;
        display: table; }

      > div {
        + div {
          margin-top: 30px; } } } }

  .inner-section-2 {
    margin-top: 25px; }

  .faixapreta {
    margin: 0 auto;
    display: table;
    background: #000;
    padding: 20px 65px;
    border-radius: 39px;
    font-size: 28px;
    font-weight: 700;
    color: #ffffff;
    text-align: center;
    margin-top: 50px;

    @include below(desktop) {
      margin-top: 30px;
      width: 100%;
      padding: 20px 15px;
      font-size: 22px;
      border-radius: 9px; }

    span {
      color: #3f908c;
      margin: 0 5px;

      @include below(desktop) {
        display: block; } }

    @include above(desktop) {
      width: 970px; } }


  .inner-section-3 {
    margin-top: 30px;
    .sinal {
      font-size: 60px;
      font-weight: 700;
      letter-spacing: -1.5px;
      line-height: 60px;

      @include below(desktop) {
        font-size: 50px; } } } }


#section-6 {
  background: #3f908c;
  .text1 {
    color: #fff;
    font-size: 28px;
    font-weight: 700;
    text-align: center;

    @include below(desktop) {
      font-size: 24px; } }

  .lista-beneficios {
    margin-top: 45px;

    @include above(tablet) {
      padding: 0 45px; }

    @include below(desktop) {
      margin-top: 30px; }

    .item {
      background: #287672;
      padding: 15px 20px 20px 20px;
      color: #fff;
      border-radius: 5px;
      margin-bottom: 20px;


      @include above(tablet) {
        display: flex;
        justify-content: space-between;
        padding: 35px 0; }

      .numero {
        font-size: 100px;
        font-weight: 700;
        line-height: 100px;

        display: flex;
        justify-content: center;

        @include above(tablet) {
          width: 16%;
          min-width: 16%;
          font-size: 160px;
          line-height: 130px; } }

      .texto {


        @include above(tablet) {
          width: 84%;
          min-width: 84%;
          padding-right: 45px; }

        @include below(desktop) {
          text-align: center; }

        h2 {
          font-size: 22px;
          font-weight: 700; }

        p {
          margin-top: 10px;
          font-size: 16px;
          font-weight: 400;
          line-height: 26px; } } } }

  .button-2 {
    a {
      font-size: 38px;
      padding: 13px 46px;

      @include below(desktop) {
        font-size: 22px;
        padding: 15px 15px; }

      i {
        font-size: 52px;

        @include below(desktop) {
          font-size: 28px; } } } } }


#section-7 {
  padding: 50px 0 90px;
  position: relative;

  @include below(desktop) {
    padding: 30px 0 30px; }

  .background-mid {
    position: absolute;
    width: 100%;
    z-index: -1;
    top: 130px;

    @include below(desktop) {
      display: none; }

    img {
      width: 100%; } }

  .text1 {
    text-align: center;
    color: #000;
    font-size: 36px;
    font-weight: 700;
    letter-spacing: -0.9px; }

  .lista-antes {


    @include above(tablet) {
      display: flex;
      justify-content: space-between; }
    .item {

      border: 1px solid #85a9a7;
      padding: 10px;
      margin-top: 45px;

      @include above(tablet) {
        width: 31%; }

      @include below(desktop) {
        margin-top: 30px; }

      img {
        width: 100%; } } } }

#section-8 {
  margin-top: 75px;

  @include below(desktop) {
    margin-top: 10px; }

  img {
    width: 100%;

    &:first-child {
      border-right: 1px solid #fff;

      @include below(desktop) {
        border-right: none;
        border-bottom: 1px solid #fff; } } } }


#section-9 {
  position: relative;
  padding: 30px 0 0px;

  @include above(tablet) {
    padding: 65px 0 30px; }
  .container {

    .image-bruno {

      @include above(tablet) {
        position: absolute;
        bottom: 0; }

      @include below(desktop) {
        margin-top: 30px;
        margin-left: auto;
        margin-right: auto;
        display: table; } } }

  .assinatura {

    margin-top: 30px;

    @include above(tablet) {
      margin-right: 100px;
      float: right; }

    @include below(large-desktop) {
      margin-right: 0;
      width: 60%; }

    @include below(desktop) {
      margin-left: auto;
      margin-right: auto;
      display: table;
      width: 95%; } }

  .faixa-verde {
    background: #3f908c;
    padding: 35px 0;
    display: inline-block;
    width: 100%;
    margin: 45px 0;

    @include below(desktop) {
      padding: 30px 0;
      margin: 30px 0; }

    .inner-section {

      color: #fff;

      @include above(tablet) {
        float: right;
        margin-right: 90px; }

      @include below(large-desktop) {
        margin-right: 0; }

      @include below(desktop) {
        text-align: center;

        br {
          display: none; } }

      .text1 {
        font-weight: 600;
        font-size: 24px;
        margin-bottom: 5px; }

      .text2 {
        font-size: 18px; } } }

  .check-section {
    display: inline-block;
    width: 100%;
    .list-check {
      ul {
        float: right;
        margin-right: 140px;

        @include below(large-desktop) {
          margin-right: 0;

          br {
            display: none; } } } } } }




#section-10 {
  background: #ecf1f1;

  .star {
    margin: 0 auto;
    display: table;
    margin-top: 30px;

    @include below(desktop) {
      width: 210px; } }


  .lista-avaliacoes {
    margin-top: 30px;
    @include above(tablet) {
      display: grid;
      grid-template-columns: 33.33% 33.33% 33.33%;
      grid-gap: 12px;
      margin-top: 60px; }

    .item {
      background: #fff;
      padding: 30px 22px;
      border-radius: 5px;
      height: 273px;

      @include below(large-desktop) {
        height: 340px; }

      @include below(desktop) {
        height: auto;
        + .item {
          margin-top: 20px; } }


      p {
        color: #000000;
        font-size: 15px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: -0.38px; } } }


  .button-2 {
    a {
      font-size: 38px;
      padding: 13px 46px;

      @include below(desktop) {
        padding: 5px 25px;
        font-size: 20px;
        line-height: 26px; }
      i {
        font-size: 52px;

        @include below(desktop) {
          font-size: 40px; } } } } }



#section-11 {
  background: #3f908c;

  .carousel-img {
    margin-top: 50px;

    @include below(desktop) {
      margin-top: 30px; }


    .slick-list {}


    .item {
      width: 33.33%;
      padding: 0 10px;
      height: 310px;

      @include below(desktop) {
        height: 190px; }

      @include below(phablet) {
        height: 260px; }

      img {
        width: 100%;
        border: 1px solid #fff;
        object-fit: cover;
        height: 100%; } } }

  .container {
    position: relative; }

  .arrow {
    position: absolute;
    transform: translateY(-50%);
    top: 55%;
    z-index: 999;
    cursor: pointer;

    @include below(desktop) {
      display: none !important; }

    &.arrow-prev {
      left: -100px;

      @media(max-width: 1440px) {
        left: -20px; } }


    &.arrow-next {
      right: -100px;
      @media(max-width: 1440px) {
        right: -20px; } } }

  .slick-dots {
    display: flex;
    justify-content: center;
    margin-top: 50px;
    flex-wrap: wrap;

    @include below(desktop) {
      margin-top: 30px;
      padding: 0 50px; }

    li {
      margin-right: 13px;
      margin-top: 5px;
      margin-bottom: 5px;
      button {
        text-indent: -99999px;
        width: 10px;
        height: 11px;
        background-color: #1e615e;
        border-radius: 50%;
        border: none; }

      &.slick-active {
        button {
          background: #fff; } } } } }





#section-12 {
  background: #f0f0f0;

  .text-1 {
    margin: 0 auto;
    display: table;
    @include above(tablet) {
      width: 900px; }
    p {
      color: #000000;
      font-size: 26px;
      font-weight: 700;
      text-align: center;
      letter-spacing: -0.87px;

      @include below(desktop) {
        font-size: 20px;
        line-height: 30px; } } } }





.form {
  margin-top: 45px;
  @include below(desktop) {
    margin-top: 15px; }
  form {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 0 auto;
    @include above(tablet) {
      width: 820px; } }
  .item {
    margin-top: 20px;
    label {
      display: block;
      font-size: 12px;
      font-weight: 600;
      color: #010101;
      margin-bottom: 5px;
      span {
        color: #ff0000; } }

    input[type=text],
    input[type=email] {
      height: 34px;
      border: 1px solid rgba(155, 155, 155, 0.3);
      background-color: #ffffff;
      width: 100%;
      font-size: 14px;
      padding: 0 10px; }

    &.item-1 {
      width: 35%;

      @include below(desktop) {
        width: 100%; } }


    &.item-2 {
      width: 27%;
      @include below(desktop) {
        width: 100%; } }

    &.item-3 {
      width: 100%; }


    textarea {
      width: 100%;
      border: 1px solid rgba(155, 155, 155, 0.3);
      background-color: #ffffff;
      height: 192px;
      font-size: 14px;
      padding: 10px 10px; }

    input[type=submit] {
      margin: 0 auto;
      display: table;
      margin-top: 35px;
      width: 218px;
      height: 59px;
      box-shadow: 0 1px 5px rgba(0, 0, 0, 0.14);
      background-color: #3f908c;
      color: #ffffff;
      font-size: 16px;
      font-weight: 700;
      transition: all 0.3s ease;
      border: none;
      border: 2px solid #ffffff;

      @include below(desktop) {
        margin-top: 15px; }

      &:hover {
        background: #236865; } } } }


#section-13 {
  .map-address {
    display: flex;
    padding: 25px 0;
    align-items: center;
    color: #000000;
    font-size: 22px;
    font-weight: 700;
    letter-spacing: -0.6px;

    @include below(desktop) {
      font-size: 15px;
      margin: 0 auto;
      justify-content: center; }

    img {
      margin-right: 20px; } }

  .mapa {
    img {
      width: 100%;

      @include below(desktop) {
        height: 190px;
        object-fit: cover; } } } }



footer {
  background: #3f908c;
  .footer {
    padding: 65px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include below(desktop) {
      flex-direction: column;
      padding: 30px 0; } }

  .logo {
    width: 471px;
    @include below(desktop) {
      width: 290px; } }


  .phone-header {
    font-size: 24px;
    font-weight: 700;
    display: flex;
    align-items: flex-end;
    color: #fff;


    @include below(desktop) {
      margin: 30px 0;
      font-size: 18px; }

    i {
      margin: 0 7px; }

    p {
      display: flex;
      align-items: flex-end; }

    img {
      margin-right: 10px;
      margin-bottom: 5px;
      width: 32px;
      @include below(desktop) {
        width: 24px; } }

    span {
      font-size: 14px;
      margin-bottom: 6px;
      margin-right: 5px;
      @include below(desktop) {
        font-size: 11px;
        margin-bottom: 4px; } } }

  .social {
    display: flex;
    a {
      width: 32px;
      height: 32px;
      background-color: #fff;
      @include flexCenter;
      color: #00918c;
      font-size: 18px;
      border-radius: 50%;
      margin-left: 10px;

      transition: all 0.3s ease;
      &:hover {
        background: #237874;
        color: #fff; } } } }


.cop {
  background: #236663;
  padding: 25px 50px 25px 50px;

  @include below(desktop) {
    padding: 30px 50px 30px 50px; }

  @include below(tablet) {
    padding: 20px 0 20px 0; }

  .inner {
    display: flex;
    justify-content: space-between;
    align-content: center;

    @include below(desktop) {
      flex-direction: column; }

    img {
      margin-left: 5px;
      width: 80px; }

    p {

      color: #8db0ae;
      text-align: center;
      font-size: 12px;
      font-weight: 400;
      line-height: 18px;

      @include below(desktop) {
        line-height: 24px; } } } }





.faixa-flutuante-top {
  position: fixed;
  top: 0;
  background: #30827e;
  width: 100%;
  padding: 5px 0;
  z-index: 999;
  box-shadow: 1px 0px 5px 2px #00000061;

  @include above(tablet) {
    display: none; }

  .phone-header {
    font-size: 16px;
    font-weight: 700;
    display: flex;
    align-items: flex-end;
    color: #fff;
    justify-content: center;

    i {
      margin: 0 7px; }

    p {
      display: flex;
      align-items: flex-end; }

    img {
      margin-right: 10px;
      margin-bottom: 5px;
      width: 20px;
      height: 20px; }

    span {
      font-size: 12px;
      margin-bottom: 4px;
      margin-right: 5px; } } }


.whatsapp-flutuante {
  @include above(tablet) {
    display: none; }
  a {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    width: 50px;
    height: 50px;
    bottom: 60px;
    right: 20px;
    background: #25d366;
    color: #fff;
    border-radius: 50%;
    text-align: center;
    font-size: 30px;
    box-shadow: 1px 1px 2px #888;
    z-index: 9999;
    transition: all 0.3s ease;
    &:hover {
      background: #13a148;
      width: 67px;
      height: 67px; }

    @include below(desktop) {
      bottom: 25px;
      width: 45px;
      height: 45px; } } }


.whatsapp-desktop {
  position: fixed;
  bottom: 60px;
  right: 20px;
  width: 140px;
  z-index: 999;
  @include below(desktop) {
    display: none; } }

.obrigado {
  padding: 100px 0;
  border-bottom: 15px solid #dff0f0;

  p {
    font-size: 38px;
    text-align: center;
    color: #000;
    font-weight: 600;
    line-height: 46px; }

  @include below(desktop) {
    padding: 50px 0;

    p {
      font-size: 24px;
      line-height: 32px; } } }
