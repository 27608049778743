//#

html {

    font-size: 62.5%;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: 'Poppins', sans-serif;

    @include between(tablet, desktop) {
        font-size: 58%; }

    @include below(tablet) {
        font-size: 50%; } }

body {
    display: inline-block;
    vertical-align: top;
    width: 100% !important;
    background: #fff;
    max-width: 100%;
    color: #000000 !important;
    height: 100% !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-x: hidden;
    font-family: 'Poppins', sans-serif;

    @include below(desktop) {
      padding-top: 30px; } }

*, *::before, *::after {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    box-sizing: border-box; }

*, *:focus, *:active {
    box-shadow: none;
    outline: none; }

img {
    max-width: 100%;
    vertical-align: bottom; }

.clearfix {
    clear: both; }



input:focus,
select:focus,
textarea:focus,
button:focus {
    outline: none !important; }

input,
select,
textarea,
button {
    outline: none !important; }


[data-scroll-animation] {
    opacity: 0;
    &.animated {
        opacity: 1; } }

h1,
h2,
h3,
h4,
h5 {
    margin: 0; }


li {
    list-style: none; }

a {
    text-decoration: none;
    color: inherit; }

a:hover {
    text-decoration: none !important; }



ul,
p {
    margin: 0;
    padding: 0; }
