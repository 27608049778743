.button-1 {
  display: table;
  margin: 0 auto;
  margin-top: 20px;
  a {
    padding: 9px 17px;
    box-shadow: 1px 3px 3px rgba(0, 0, 0, 0.4);
    border-radius: 7px;
    border: 2px solid #ffffff;
    background-color: #0c534f;
    font-size: 22px;
    font-weight: 700;
    letter-spacing: -0.55px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease;

    @include below(mobile) {
      padding: 11px 10px;
      font-size: 19px; }


    &:hover {
      background: #237874; }

    i {
      margin-left: 15px;
      font-size: 34px;
      font-weight: 400;

      @include below(mobile) {
        font-size: 28px; } } } }


.button-2 {
  display: table;
  margin: 0 auto;
  margin-top: 60px;

  @include below(desktop) {
    margin-top: 30px;
    width: 100%; }

  a {
    padding: 18px 45px;
    box-shadow: 1px 4px 6px rgba(0, 0, 0, 0.43);
    border-radius: 9px;
    border: 3px solid #ffffff;
    background-color: #0c534f;
    font-size: 30px;
    font-weight: 700;
    letter-spacing: -0.55px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    transition: all 0.3s ease;
    &:hover {
      background: #237874; }

    @include below(desktop) {
      padding: 15px 15px;
      font-size: 23px; }

    @include below(phablet) {
      font-size: 18px; }
    i {
      margin-left: 30px;
      font-size: 45px;
      font-weight: 400;

      @include below(desktop) {
        font-size: 35px;
        margin-left: 10px; }

      @include below(phablet) {
        font-size: 28px; } } } }


.title-1 {
  font-size: 30px;
  font-weight: 700;
  letter-spacing: -0.75px;

  @include below(desktop) {
    font-size: 22px; } }


.title-2 {
  text-align: center;
  color: #000;
  font-size: 36px;
  font-weight: 700;
  letter-spacing: -0.9px;

  @include below(desktop) {
    font-size: 32px;
    line-height: 38px; } }

.text-center {
  text-align: center; }


.primary-text {
  color: #4c4c4c;
  font-size: 16px;
  font-weight: 500;
  line-height: 30px; }

.second-text {
  font-size: 22px;
  font-weight: 700;
  letter-spacing: -0.55px;

  @include below(desktop) {
    font-size: 20px; } }

.third-text {
  font-size: 26px;
  font-weight: 700;
  letter-spacing: -0.65px;

  @include below(desktop) {
    font-size: 24px; } }

.fourth-text {
  font-size: 40px;
  font-weight: 700;
  letter-spacing: -1px;

  @include below(desktop) {
    font-size: 29px; } }

.color-black {
  color: #000 !important; }

.color-greenDark {
  color: #30827e !important; }

.color-white {
  color: #fff !important; }

.padding-section {
  padding: 65px 0;

  @include below(desktop) {
    padding: 30px 0; } }
